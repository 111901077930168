.wc-card, .wc-card p .wc-card p b, .wc-card p span {
    font-size: 14px !important;
}
.outer-slide-container {
    width: 100%;
    padding: .5rem;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide-content-container {
    width: 100%;
    /* border: 1px solid red; */
    padding: 10px;
}

.slide-content-container .main-title {
    font-size: 20px;
}

.slide-content-container.arm {
    background-color:#e4dfec;
}
.slide-content-container.apm {
    background-color:#dce6f1;
}
.slide-content-container.invm {
    background-color:#f2dcdb;
}
.slide-content-container .slide-content {
    font-size: 12px !important;
}
.slide-content-container .slide-content p.title {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
}
.slide-content .item-cnt {
    margin-bottom: 5px;
    margin-top: 5px;
}
.slide-content .item-cnt label{
    margin-right: 10px;
    font-weight: bold;
    display:inline-block;
    width: 14%;
}
.slide-content input {
    text-align: center;
    font-size: 12.5px;
}
.slide-content .divider-matrix {
    font-size: 10px;
    align-items: center;
}
.slide-content .divider-matrix .divider {
    width: 50%;
    height: 1px;
    border: 1px solid
}
.slide-content .divider-matrix p {
    margin-bottom: 0px;
}
.slide-content .divider-matrix span {
    font-weight: bold;
    margin-left: 5px;
}
.slide-content .divider-matrix input {
    width: 60%;
    margin-left: 5px;
}

.slide-content .desclaimer {
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
}

.slide-content .comment {
    border: 1px solid #000;
    background-color: #fff;
    cursor: default;
    color: #000;
    font-size: 12.5px;
    min-height: 200px;
    padding: 10px;
}

.slide-content .comment ol {
    counter-reset: list;
    padding: 0;
}
.slide-content .comment ol > li {
    list-style: none;
    margin-bottom: 4px;
}

.slide-content .comment ol > li::before {
    content: "(" counter(list) ") ";
    counter-increment: list;
}

.slide-content .comment ol li span {
    color: red !important;
}


.standalone-content {
    width: 100%;
    border: 4px solid black;
    min-height: 400px;
    padding: 15px;
}

.standalone-content.arm {
    background-color: #4f81bd;
}

.standalone-content.apm {
    background-color: #13294b;
    color: #fff;
}

.standalone-content.inv {
    background-color: #1f497d;
    color: #fff;
}

.standalone-content .title {
    text-align: center;
    color: #FFF;
    font-size: 20px;
    /* font-weight: bold; */
    text-decoration: underline;
}

.standalone-content .sub-title {
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
    color: #000;
    font-weight: 500;
}

.standalone-content.apm .sub-title, .standalone-content.inv .sub-title {
    color: #fff
}

.standalone-content div.label-value {
    display: flex;
}
.standalone-content div.label-value label {
    font-weight: bold;
    margin-right: 25px;
    display: inline-block;
    min-width: 10%;
}

.standalone-content p.input-label {
    border: 2px solid black;
    padding: 2px 20px;
    min-width: 150px;
    background-color: #fff;
    margin-bottom: 0px;
    text-align: center;
    font-size: 14px;
}
.standalone-content p.input-label span {
    color: #000 !important;
    font-weight: 200;
    font-size: 14px;
}

.standalone-content .divider-matrix {
    display: flex;
    align-items: center;
}
.standalone-content .divider-matrix .divider {
    width: 50%;
    height: 1px;
    border: 1px solid #000;
}
.standalone-content .divider-matrix span {
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.desclaimer ul li {
    list-style-type:disc;
}
.clogo {
    text-align: center;
}
.clogo img {
    max-width: 200px;
}
.info-acct {
    text-align: center;
}
.info-acct img {
    max-width: 250px;
}




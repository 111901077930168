.financial-metrics .col-12 {
    margin-bottom: 20px;
}
.financial-metrics .col-12 div.mcontainer {
    color: #000;
    opacity: 0.4;
    display: flex;
    transition: all 0.5s;
}
.financial-metrics .col-12 div p {
    margin-bottom: 5px;
}
.icon-check {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #000;
    margin-right: 15px;
    opacity: 0.5;
    transition: all 0.5s;
  }
.checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 24px;
    color: #000;
    font-weight: bold;
    transition: all 0.5s;
  }
  .checkmark::before {
    transition: all 0.5s; 
  }

.financial-metrics .col-12 div p:first-child {
    font-size: 14px;
    font-weight: bold;
    color: #14131399;
}
.financial-metrics .col-12 div p:last-child {
    font-size: 12px;
    color: #28272799;
    font-style: italic;
}

.mcontainer.enable {
    opacity: 1 !important;
}

.mcontainer.enable .icon-check {
    border-color: #92d050;
    opacity: 1 !important;
}

.mcontainer.enable .icon-check .checkmark {
    color: #92d050;
}
.mcontainer.enable .icon-check .checkmark::before {
    /* border-color: #92d050; */
    content: "";
    position: absolute;
    top: 42%;
    left: 25%;
    width: 10px;
    height: 20px;
    border: 3px solid #92d050;
    border-width: 0 2px 2px 0;
    transform: translateY(-50%) rotate(45deg);
}
